import $ from 'jquery';

const sectionColorSlider = $('.section-color-slider')
const rangeColor = $('#customRangeColor')

// Set default background color with value of range
sectionColorSlider.css('background-color', `hsla(${rangeColor.val()}, 100%, 50%, 0.7)`)

rangeColor.on('input', function() {
    sectionColorSlider.css('background-color', `hsla(${$(this).val()}, 100%, 50%, 0.7)`)
})
import $ from 'jquery';

if($('#btnCaricaNews input').val() < 4) {
    $('#btnCaricaNews').hide();
}
if($('#btnCaricaProgetti input').val() < 4) {
    $('#btnCaricaProgetti').hide();
}

$('#btnCaricaNews').on('click', function() {
    console.log('Click');
    var _currentOffset = $('#newsList .card--news').length;
    var _totalNews = $('#btnCaricaNews input').val();
    console.log(_totalNews);

    // Ajax request
    $.ajax({
        url: window.location.href,
        type: 'GET',
        data: {
            'offset': _currentOffset,
            'type': 'news'
        },
        beforeSend: function() {
            $('#btnCaricaNews').attr('disabled', 'disabled');
        },
        success: function(res) {
            $('#newsList').append(res);
            $('#btnCaricaNews').removeAttr('disabled');
            if($('#newsList .card--news').length >= _totalNews) {
                $('#btnCaricaNews').hide();
            }
        },
        error: function (xhr, ajaxOptions, thrownError) {
            console.log(xhr.status);
            console.log(thrownError);
        }
    })
});

$('#btnCaricaProgetti').on('click', function() {
    console.log('Click');
    var _currentOffset = $('#projectList .card--news').length;
    var _totalNews = $('#btnCaricaProgetti input').val();
    console.log(_totalNews);

    // Ajax request
    $.ajax({
        url: window.location.href,
        type: 'GET',
        data: {
            'offset': _currentOffset,
            'type': 'project'
        },
        beforeSend: function() {
            $('#btnCaricaProgetti').attr('disabled', 'disabled');
        },
        success: function(res) {
            $('#projectList').append(res);
            $('#btnCaricaProgetti').removeAttr('disabled');
            if($('#projectList .card--news').length >= _totalNews) {
                $('#btnCaricaProgetti').hide();
            }
        },
        error: function (xhr, ajaxOptions, thrownError) {
            console.log(xhr.status);
            console.log(thrownError);
        }
    })
});
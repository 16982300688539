import $ from 'jquery';


/* When the user scrolls down, hide the navbar.
When the user scrolls up, show the navbar */
var doc = document.documentElement;
  var w = window;

  var prevScroll = w.scrollY || doc.scrollTop;
  var curScroll;
  var direction = 0;
  var prevDirection = 0;

  var header = document.getElementById('headerNavbar');

  var checkScroll = function() {

    /*
    ** Find the direction of scroll
    ** 0 - initial, 1 - up, 2 - down
    */

    curScroll = w.scrollY || doc.scrollTop;
    if (curScroll > prevScroll) { 
      //scrolled up
      direction = 2;
    }
    else if (curScroll < prevScroll) { 
      //scrolled down
      direction = 1;
    }

    if (direction !== prevDirection) {
      toggleHeader(direction, curScroll);
    }
    
    prevScroll = curScroll;
  };

  var toggleHeader = function(direction, curScroll) {
    if (direction === 2 && curScroll > 100) { 
      
      //replace 52 with the height of your header in px

      header.classList.add('slide-down');
      prevDirection = direction;
    }
    else if (direction === 1) {
      header.classList.remove('slide-down');
      prevDirection = direction;
    }
  };
  
  window.addEventListener('scroll', checkScroll);


/* Quando clicchi il pulsante "toggler", apri il menu in overlay */
$('.navbar-toggler[data-toggle="fade-collapse"]').on('click', function() {
    $('body').toggleClass('overflow-hidden')
    $(this).toggleClass('open-menu')
    $('#headerNavbar .navbar-collapse').toggleClass('open-menu')
})
/* Quando clicchi il link del menu tolgo l'overflow al body */
$('#headerNavbar .navbar-collapse .nav-link').on('click', function() {
    $('body').removeClass('overflow-hidden')
    $('#headerNavbar .navbar-toggler[data-toggle="fade-collapse"]').removeClass('open-menu')
    $('#headerNavbar .navbar-collapse').removeClass('open-menu')
})

// https://swiperjs.com/
import Swiper, {Navigation, Pagination} from 'swiper';

// Configure Swiper to use modules
Swiper.use([Navigation, Pagination]);


// Single slide automotive
const swiperSingle = new Swiper('.swiper-container--automotive', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 8,
    mousewheel: true,
    centeredSlides: true,
    breakpoints: {
        // when window width is >= 992px
        992: {
            slidesPerView: 3,
        }
    }
});


// var elementSwiperSingle = document.querySelector('.swiper-container--automotive');

// elementSwiperSingle.addEventListener('mouseenter', function(event){
//     document.body.classList.add('overflow-hidden');
// });
// elementSwiperSingle.addEventListener('mouseleave', function(event){
//     document.body.classList.remove('overflow-hidden');
// });
// elementSwiperSingle.addEventListener('wheel', function(event){
//     console.log('WHEEL!', event);
//     if(event.deltaY > 0){
//         swiperSingle.slidePrev();
//     } else {
//         swiperSingle.slideNext();
//     }
// });